<template>
    <!-- <div class="card" > -->
        <!-- <div class="container"> -->
            <div class="row justify-content-center" style="height:350px; background-color:#FFF5EE">
                <!-- <div class="col-md-12"> -->
                    <infinite-slide-bar duration="40s" style="width:950px">
                        <div class="items">
                            <div v-for="item in announce" :key="item.key">
                            <div>
                                <div class="f-w-bold" style="display: flex; justify-content:space-evenly; font-weight:bold;
                                font-size:25px; padding-top:120px">
                                {{ item.title }}
                                </div><br>
                                <div style="display: flex; justify-content:space-evenly; padding-left:100px">
                                {{ item.content }}
                                </div>
                            </div>
                            </div>
                        </div>
                    </infinite-slide-bar>
                <!-- </div> -->
            </div>
            <!-- <splide :options="options">
                <splide-slide>
                    <img src="img/image01.png">
                </splide-slide>
                <splide-slide>
                    <img src="img/image02.png">
                </splide-slide>
                <splide-slide>
                    <img src="img/image03.png">
                </splide-slide>
            </splide> -->
        <!-- </div> -->
    <!-- </div> -->
</template>

<script>
    import carousel from "vue-owl-carousel";
    import axios from 'axios';
    import Vue from 'vue';
    // import App from './App';
    import InfiniteSlideBar from 'vue-infinite-slide-bar';
    // import VueSplide from '@splidejs/vue-splide';
    // import { Splide, SplideSlide } from '@splidejs/vue-splide';

    // Vue.use( VueSplide );

    // new Vue( {
    //     el    : '#app',
    //     render: h => h( App ),
    // });

    Vue.component('InfiniteSlideBar', InfiniteSlideBar)
    export default {
    // components: {
    //     Splide,
    //     SplideSlide,
    // },
    props: {
        msg: String
    },
    data: function() {
        return {
            options: {
			  	rewind : true,
                width  : 10,
                perPage: 1,
                gap    : '1rem',
            },
            announce: [],
            response: [],
        }   
    },
    mounted() {
        axios
            .get('/api/view-announcement')
            .then(response => {
                this.announce = response.data;
            });
        },
    };
</script>
<style> .items { display: flex; justify-content: space-evenly;} </style>

