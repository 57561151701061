var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row justify-content-center",
      staticStyle: { height: "350px", "background-color": "#FFF5EE" }
    },
    [
      _c(
        "infinite-slide-bar",
        { staticStyle: { width: "950px" }, attrs: { duration: "40s" } },
        [
          _c(
            "div",
            { staticClass: "items" },
            _vm._l(_vm.announce, function(item) {
              return _c("div", { key: item.key }, [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "f-w-bold",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-evenly",
                        "font-weight": "bold",
                        "font-size": "25px",
                        "padding-top": "120px"
                      }
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(item.title) +
                          "\n                            "
                      )
                    ]
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-evenly",
                        "padding-left": "100px"
                      }
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(item.content) +
                          "\n                            "
                      )
                    ]
                  )
                ])
              ])
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }